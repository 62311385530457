import React from "react"

// stack
import react from "../../images/react.png"
import node from "../../images/node.png"
import ts from "../../images/ts.png"
import mysql from "../../images/mysql.png"
import vite from "../../images/vite.png"
import bootstrap from "../../images/bootstrap.png"
import tailwind from "../../images/tailwind.png"
import django from "../../images/django.png"
import d3 from "../../images/d3.png"
import mongo from "../../images/mongo.png"
import socket from "../../images/socketio.svg"
import s3 from "../../images/s3.png"
import sass from "../../images/sass.png"
// links
import github from "../../images/github.svg"
import boxArrow from "../../images/box-arrow-up-right.svg"
import fridgeDemo from "../../images/fridge-demo.png"
const Storyboard = () => {
  return (
    <div className="project-two">
      <div className="proj-left">
        {" "}
        <div className="title-card">
          <a href="https://thefridgesystem.netlify.app/" target="_blank">
            <h1 id="fridgeTitle">The Fridge</h1>
          </a>

          <div className="app-links" id="socials">
            {" "}
            <a href="https://github.com/glumslug/fridge" target="_blank">
              <img src={github} alt="" />
            </a>
            <a href="https://thefridgesystem.netlify.app/" target="_blank">
              <img src={boxArrow} alt="" />
            </a>
          </div>
        </div>
        <div className="description">
          <p className="tagline">
            {" "}
            Recipe book, shopping list, and home inventory system — all in one.
          </p>
          <p>
            A web-app for creating/tracking recipes, and using those to shop.
            The app tracks what you have, and lets you create shopping lists for
            what you need.
          </p>
        </div>
        <div className="description stack">
          <ul>
            <li>
              <img src={react} alt="" />
            </li>
            <li>
              <img src={node} alt="" />
            </li>

            <li>
              <img src={ts} alt="" />
            </li>

            <li>
              <img src={mysql} alt="" />
            </li>
            <li>
              <img src={vite} alt="" />
            </li>

            <li>
              <img
                src={bootstrap}
                style={{ height: "2.2rem", padding: "6px 0" }}
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>

      <div className="proj-right">
        <img src={fridgeDemo} alt="" />
      </div>
    </div>
  )
}

export default Storyboard
